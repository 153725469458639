import { environment } from './../../../../environments/environment';
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { UserInfoService } from "./../../../services/user-info.service";
import { SSOService } from "../../../services/sso.service";
import { ToastrService } from "ngx-toastr";
import { LogoutParams } from "../../../models/SSO/logout";
import { delay } from "rxjs/operators";
import { of as observableOf } from "rxjs";
import { IsLoadingService } from "@service-work/is-loading";

@Component({
  selector: "logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent implements OnInit {
  public url: string;
  public logoutParams: LogoutParams;
  localEnvironment: any;

  @ViewChild("logoutForm", { static: false }) logoutForm: ElementRef;

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private activatedRoute: ActivatedRoute,
    private ssoService: SSOService,
    private toastr: ToastrService,
    private isLoadingService: IsLoadingService
  ) {
    this.localEnvironment = environment;
  }

  ngOnInit() {
    const redirectUrl: string =
      this.activatedRoute.snapshot.queryParams["redirectUrl"];
    this.ssoService.deleteToken().subscribe(
      (logoutUrl) => {
        clearInterval(this.ssoService.refreshRef);
        if (logoutUrl.includes("id_token_hint")) {
          this.url = logoutUrl.split("?")[0];
          this.logoutParams = decodeURI(logoutUrl)
            .replace("?", "&")
            .split("&")
            .map((param) => param.split("="))
            .reduce((values, [key, value]) => {
              values[key] = value;
              return values;
            }, {} as LogoutParams);
          this.userInfoService.clearLocalCache();
          window.location.href = logoutUrl;
          observableOf(true)
            .pipe(delay(250))
            .subscribe(() => {
              this.logoutForm.nativeElement.submit();
            });
        } else {
          this.userInfoService.clearLocalCache(logoutUrl);
          this.userInfoService.clearUserInfo();
          sessionStorage.clear();
          window.location.href = logoutUrl;
        }
      },
      (error) => {
        sessionStorage.clear();
        this.isLoadingService.remove();
        setTimeout(() => {
          this.router.navigate(["./"]);
        }, 100);
      }
    );

  }

  showError(message: string, title: string) {
    this.toastr.error(message, title);
  }
}
