import { environment } from '../../environments/environment';

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  { name: 'Sisense SDK', src: `${environment.appConfig.webcomponents}sisense-sdk-element/latest/sisense-sdk-element.js` },
  { name: 'ODI', src: `${environment.appConfig.webcomponents}odi-element/latest/odi-element.js` },
  { name: 'User Detail', src: `${environment.appConfig.webcomponents}core-user-details-element/latest/user-detail-element.js` },
  { name: 'Gateway Drawer', src: `${environment.appConfig.drawerGetWay}` }
]
