import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetResourceResponse } from "app/models/Provisioner/Provisioner";
import { environment } from "environments/environment";
import { catchError, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProvisionerService {
  constructor(private httpClient: HttpClient) {}

  getResources(token: string) {
    const url = environment.appConfig.provisionerUrl + "/v2/resources";

    return this.httpClient
      .get<GetResourceResponse>(url, {
        headers: { Authorization: token },
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return of(null);
        })
      );
  }
}
