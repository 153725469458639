import { Component } from '@angular/core';
import { UserInfoService } from "./../../services/user-info.service";
import { environment } from "./../../../environments/environment";
import { SSOService } from '../../services/sso.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent {

  userConfig;

  constructor(
    public userInfoService: UserInfoService,
    public ssoService: SSOService
  ) {
    this.constructUserConfig();
  }

  constructUserConfig() {
    this.userInfoService.authToken$.subscribe((token) => {
      if (token && token.authToken && token.decodedToken) {
        this.userConfig = {
          entitlement: "CapSolution",
          tenantInfraKey: ['capWorkspaceUrl'],
          token: token.authToken.slice(7),
          isHeaderVisible: true,
          appName: 'banking',
          urlDetail: {
            entitlementUrl: environment.appConfig.ssoServiceEndpoint.slice(0, -8)
          }
        }
      }

    });
  }

    renewToken(e?) {
      this.ssoService.renewAuthToken().subscribe((res: any) => {
        if (!(res instanceof HttpErrorResponse)) {
          this.constructUserConfig();
        }
      });
    }

}
