import { Subscription, empty as ObservableEmpty } from "rxjs";

import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

import { ToastrService } from "ngx-toastr";

import { slideBounceBottomToTopAnimation } from "../../../animations/animations";
import { BlockerService } from "./../../../services/blocker.service";
import { SSOService } from "./../../../services/sso.service";
import { UserInfoService } from "./../../../services/user-info.service";
import { NewPasswordValidator } from "./../../../validators/new-password-validator";
import { catchError } from "rxjs/operators";
import { IsLoadingService } from "@service-work/is-loading";
import { HttpErrorResponse } from "@angular/common/http";

// Validators
// Services
@Component({
  selector: "account-settings",
  templateUrl: "./account-settings.component.html",
  styleUrls: ["./account-settings.component.scss"],
  animations: [slideBounceBottomToTopAnimation],
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  authToken: string;
  userName: string;
  firstName: string;
  lastName: string;

  externalAppName: string;
  returnUrl: string;

  changePasswordForm: UntypedFormGroup;
  currentPasswordCtrl: UntypedFormControl;
  newPasswordCtrl: UntypedFormControl;
  confirmNewPasswordCtrl: UntypedFormControl;

  showPasswords = false;
  triggerAccountSettingAnimation = false;

  selectedAccountSettingIndex = 1;
  private subscriptions = new Subscription();
  getTokenSubscription = new Subscription();
  federatedUser = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    public userInfoService: UserInfoService,
    private ssoService: SSOService,
    private toastr: ToastrService,
    private isLoadingService: IsLoadingService
  ) {
    this.externalAppName = this.activatedRoute.snapshot.queryParams["appName"];
    this.returnUrl = this.activatedRoute.snapshot.queryParams["returnUrl"];

    this.authToken = userInfoService.getAuthToken();

    setTimeout((time) => {
      this.triggerAccountSettingAnimation = true;
    }, 0);
  }

  ngOnInit(): void {
    this.isLoadingService.remove();
    this.subscribeToUserNames();
    this.currentPasswordCtrl = this.formBuilder.control(
      "",
      Validators.required
    );
    this.newPasswordCtrl = this.formBuilder.control("", Validators.required);
    this.confirmNewPasswordCtrl = this.formBuilder.control(
      "",
      Validators.required
    );

    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: this.currentPasswordCtrl,
        newPassword: this.newPasswordCtrl,
        confirmNewPassword: this.confirmNewPasswordCtrl,
        userName: this.userName,
      },
      {
        validator: Validators.compose([
          NewPasswordValidator.MatchPassword,
          NewPasswordValidator.CheckPasswordRestrictions,
        ]),
      }
    );

    this.ssoService.tokenValidation().subscribe((res) => {
      if (!(res instanceof HttpErrorResponse) && res?.length !== undefined) {
      } else {
        this.ssoService.initiateLogout();
      }
    })

    this.getUserFederatedGroups();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.getTokenSubscription.unsubscribe();
  }

  getUserFederatedGroups() {
    this.ssoService.getUserFederatedGroups().subscribe((res) => {
      if (!(res instanceof HttpErrorResponse) && res?.federatedGroups?.length > 0) {
        this.federatedUser = true;
        this.showInfo('Federated users cannot change their passwords');
      }
    })
  }

  goBackToProduct() {
    if (window.history.length === 1) {
      this.router.navigate(["./applications"]);
    } else {
      this.location.back();
    }
  }

  savePassword(): void {
    this.isLoadingService.add();
    this.ssoService.changePassword(this.changePasswordForm.value)
      .subscribe((response) => {
        if (response.ok) {
          this.showSuccess("Your changes were saved.", "Success");
          this.showPasswords = false;
          this.changePasswordForm.reset();
          this.userInfoService.setForceResetPassword(false);
        } else {
          this.showError("Invalid current password.", "Error");
        }
        this.isLoadingService.remove();
      });
  }

  cancelPassword(): void {
    this.showPasswords = false;
    this.changePasswordForm.reset();
  }

  showPasswordsToggle() {
    if (this.showPasswords === false) {
      this.showPasswords = true;
    } else {
      this.showPasswords = false;
    }
  }

  toggleHighlight(anchorIndex: number) {
    if (this.selectedAccountSettingIndex !== anchorIndex) {
      this.selectedAccountSettingIndex = anchorIndex;
    }
  }

  // Toast Notification Functions
  showSuccess(message: string, title: string) {
    this.toastr.success(message, title);
  }

  showError(message: string, title: string) {
    this.toastr.error(message, title);
  }

  showWarning(message: string, title: string) {
    this.toastr.warning(message, title);
  }

  showInfo(message: string) {
    this.toastr.info(message);
  }

  private subscribeToUserNames() {
    this.subscriptions.add(
      this.userInfoService.authToken$.subscribe(() => {
        this.userName = this.userInfoService.getUserName();
        this.firstName = this.userInfoService.getFirstName();
        this.lastName = this.userInfoService.getLastName();
      })
    );
  }
}
