import { Component, OnInit, Input } from '@angular/core';

import { environment } from "../../../../../../environments/environment";
import { UserInfoService } from "app/services/user-info.service";
import { first, map, mergeMap } from "rxjs";
import { SSOService } from "app/services/sso.service";

@Component({
  selector: "app-additionalresourcecard",
  templateUrl: "./additionalresourcecard.component.html",
  styleUrls: ["./additionalresourcecard.component.scss"],
})
export class AdditionalresourcecardComponent implements OnInit {
  @Input() list;
  localEnvironment: typeof environment;

  fileTransferEnabled = false;

  constructor(
    private ssoService: SSOService,
    private userInfoService: UserInfoService
  ) {
    this.localEnvironment = environment;
  }

  ngOnInit(): void {
    this.checkIfBmftEnabled();
  }

  checkIfBmftEnabled() {
    if (!this.localEnvironment.appConfig.fileTransfer) {
      return;
    }
    this.userInfoService.authToken$
      .pipe(
        first((val) => !!val.authToken && !!val.decodedToken),
        mergeMap((val) =>
          this.ssoService
            .getTenantSites(val.authToken)
            .pipe(
              map((res) => ({ res, email: (val.decodedToken as any)?.email }))
            )
        )
      )
      .subscribe(({ res, email }) => {
        this.fileTransferEnabled =
          res?.some((site) =>
            site.applications?.some((app) =>
              app.environments?.some((env) => env.sftp?.users[email])
            )
          ) ?? false;
      });
  }
}
