import { Component, AfterContentChecked, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { SSOService } from "./../../../services/sso.service";
import { UserInfoService } from "./../../../services/user-info.service";
import { AnimationTriggerService } from "./../../../services/animation-trigger.service";
import { landingData } from "../../../data/landing/landing-data";
import { NotificationService } from "./../../../services/notifications.service";
import { IsLoadingService } from "@service-work/is-loading";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  // holds most of the data for the static content shown on the page
  pageContentData = landingData;
  isLoggedIn = false;
  userName: string;
  // public isLoggedIn: BehaviorSubject = new BehaviorSubject<any[]>();
  ssoLoginEndpoint: string;
  localEnvironment: any;
  public get width() {
    return window.innerWidth;
  }

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
    private animationTriggerService: AnimationTriggerService,
    public ssoService: SSOService,
    public notification: NotificationService,
    public isLoadingService: IsLoadingService
  ) {
    this.ssoLoginEndpoint =
      environment.appConfig.ssoServiceEndpoint + "/auth/login";
    this.localEnvironment = environment;
  }

  ngOnInit() {
    this.ssoService.tokenValidation().subscribe((res) => {
      if (sessionStorage.getItem('authToken') !== null && !(res instanceof HttpErrorResponse)) {
          this.isLoggedIn = true;
          this.userName = this.userInfoService.getUserName();
      } else {
        this.isLoggedIn = false;
      }
    })
  }
}
